import HomeIcon from '@heroicons/react/20/solid/HomeIcon';

export interface Page {
  title: string;
  current?: boolean;
  href: string;
}

type Props = {
  pages: Page[];
};

export default function SiteBreadcrumbs(props: Props) {
  const { pages } = props;
  return (
    <nav className="flex pb-6 pt-2" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2 sm:space-x-4">
        <li>
          <div>
            <a href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page) => (
          <li
            key={page.title}
            className={`p-0 ${page.current ? 'hidden sm:inline-block' : ''}`}
          >
            <div className="flex items-center">
              <svg
                className="shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {(page.current || !page.href) && (
                <span className="ml-2 sm:ml-4 text-sm font-medium text-gray-500">
                  {page.title}
                </span>
              )}
              {!page.current && page.href && (
                <a
                  href={page.href}
                  className="ml-1 sm:ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.title}
                </a>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
